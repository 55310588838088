import React from "react";
import Header from "../components/common/header/header";
import Footer from "../components/common/footer/footer";
import Sidebar from "../components/common/sidebar/sidebar";
import { connect } from "react-redux";

class PageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdminPanel: false,
    };
  }

  componentDidMount() {
    const urlSegment = window.location.href;
    if (urlSegment.includes("admin")) {
      this.setState({ isAdminPanel: true });
    }
  }

  render() {
    // console.log(this.props.login)
    // if(typeof(this.props.login) === "undefined"){
    //   return <div className="loader"></div>
    // }

    let login = false;
    if (this.props.login) {
      login = this.props.login.isAuth;
    }

    // console.log(login);
    // const { isAdminPanel } = this.state;
    // console.log(isAdminPanel);
    return (
      <div className="wrapper">
        {/* {isAdminPanel && <Header />} */}
        {this.props.login && <Header />}
        <div className={login ? `body-wrapper` : `not-login`}>
          {login && <Sidebar />}
          {/* <Sidebar /> */}
          <div className={login ? `body-rightbar` : `not-login`}>
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.user.login,
  };
}

export default connect(mapStateToProps)(PageLayout);
