import React, { Component } from "react";
import { URL } from "./path";
class StartSessionButton extends Component {
  constructor(props) {
    super(props);
    this.sessionStart = this.sessionStart.bind(this);
  }


  sessionStart(){
    // this.props.click();
    // console.log(this.props)
    const meetingUrl = `/join?m=${this.props.meetingID}`;
    window.open(`${URL}${meetingUrl}`);
  }

  render() {
    return (
      <>
        <span id="myid"></span>
        <button  className="btn btn-primary" onClick={this.sessionStart} target="_blank" disabled={this.props.disable} > Start Session </button>
      </>
    );
  }
}

export default StartSessionButton;
