import axios from "axios";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function auth() {
  const token = getCookie("hashToken");
  // console.log(token)
  const req = axios
    .get("/screen-share-stage/login/auth", { token })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());

  return {
    type: "AUTH_CHECK",
    payload: req,
  };
  // return {
  //   type: "AUTH_CHECK",
  //   payload: req
  // };
}

export function fetchUsers() {
  const req = axios
    .get("/screen-share-stage/users", {})
    .then((resp) => resp.data)
    .catch(console.error());
  return {
    type: "LIST_USERS",
    payload: req,
  };
}

export function loginUser(username, password) {
  const req = axios
    .post("/screen-share-stage/login", { username, password })
    .then((resp) => {
      if (resp.data.isAuth) document.cookie = `hashToken=${resp.data.token}`;

      return resp.data;
    })
    .catch(console.error());
  return {
    type: "USER_LOGIN",
    payload: req,
  };
}

export function clearLoginUser() {
  return {
    type: "USER_LOGIN_CLEAN",
    payload: {},
  };
}

export function logout(id) {
  const req = axios
    .post("/screen-share-stage/logout", {
      id,
    })
    .then((resp) => resp.data)
    .catch(console.error());

  return {
    type: "USER_lOGOUT",
    payload: req,
  };
}

export function changePassword(password) {
  const req = axios
    .post("/screen-share-stage/changePassword", { password })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());
  return {
    type: "CHANGE_PASSWORD",
    payload: req,
  };
}

export function changeStatus(id, status) {
  const req = axios
    .post("/screen-share-stage/changeStatus", { id, status })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());
  return {
    type: "CHANGE_STATUS",
    payload: req,
  };
}

export function adduser(firstname, lastname, email, password, role, actionType, id ) {
  const req = axios
    .post("/screen-share-stage/adduser", {
      firstname,
      lastname,
      email,
      password,
      role,
      actionType,
      id
    })
    .then((resp) => {
      return resp.data;
    })
    .catch(console.error());
  return {
    type: "Add_USER",
    payload: req,
  };
  
}

export function getLogs(){
  const req = axios
  .get("/screen-share-stage/logs")
  .then((resp) => resp.data)
  .catch(console.error());
return {
  type: "GET_CUS_LOGS",
  payload: req,
};
}