import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../../actions/user"
class Header extends Component {

  logout() {
    this.props.dispatch(logout(this.props.login.userId));
    setTimeout(() => {
      window.location.replace("/login");
    }, 2000);
  }

  render() {
    let loggedIn = false;
    let isAdmin = false;
    if (this.props.login) {
      if (this.props.login.isAuth) {
        loggedIn = true;
        if (this.props.login.role === "1") {
          isAdmin = true;
        }
      }else {
        return <> </>
      }
    }
    return (
      <header className="header">
        <div className="container-fluid">
          <div className="header-in">
            <div className="brandlogo">
              <a href="/">
                <img src="/images/logoblue.png" alt="logo" />
              </a>
            </div>
            <ul className="header-menu">
              <li>
                {loggedIn ? (
                  <Link to="#" onClick={this.logout.bind(this)}>
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    <span>Logout</span>
                  </Link>
                ) : (
                  <Link to="/Login">
                    <i className="fa fa-power-off" aria-hidden="true"></i>
                    <span>Login</span>
                  </Link>
                )}
              </li>
              <li>
                <a href="/">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <span> {isAdmin ? "Admin User" : "Manager"}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.user.login,
  };
}

export default connect(mapStateToProps)(Header);
