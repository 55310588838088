import React, { Component } from "react";
import StartSessionButton from "./lib/StartSessionButton";
import Timer from "./lib/timer";
import { URL } from "./lib/path";
import { connect } from "react-redux";
import axios from "axios";
import { notification } from "antd";

class Addsession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      key: "",
      code: "",
      meetingID: "",
      showTimer: false,
      invite: true,
      email: "",
    };
    this.submitAddSessionForm = this.submitAddSessionForm.bind(this);
  }

  ISODateString(d) {
    function pad(n) {
      return n < 10 ? "0" + n : n;
    }
    return (
      d.getFullYear() +
      "-" +
      pad(d.getMonth() + 1) +
      "-" +
      pad(d.getDate()) +
      "T" +
      pad(d.getHours()) +
      ":" +
      pad(d.getMinutes()) +
      ":" +
      pad(d.getSeconds()) +
      "Z"
    );
  }

  async submitAddSessionForm(e) {
    e.preventDefault();
    const email = e.target.email.value || "no-email";
    const cusId = e.target.id.value;
    const desc = e.target.desc.value || "no-disc";
    const number = e.target.number.value || "no-number";
    this.setState({email: email});
    // let name;
    // if (email) {
    //   name = email.split("@")[0];
    // } else {
    //   name = `user${e.target.id.value}`;
    // }
    // let d = new Date();
    // let meetTime =  this.ISODateString(d);
    const resp = await axios.post("/create-meeting", {
      userId: this.props.user.userId,
    });
    let senData = {
      meetId: resp.data.id,
      cemail: email,
      email: this.props.user.email,
      cid: cusId,
      desc: desc,
      contact: number,
    };

    let session = await axios.post("/screen-share-stage/session", { senData });

    console.log(session.data);
    // return;
    // const url = `/start-meeting?m=${e.target.id.value}&n=${name}`;
    this.setState({
      key: resp.data.id,
      code: `${URL}/join-with-qr-code?url=${resp.data.id}`,
      meetingID: resp.data.id,
      disable: false,
      invite: false,
      showTimer: true,
    });
  }

  copyBtnHandler(selectedId) {
    var copyText = document.getElementById(selectedId);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    // alert();
    notification.success({
      message: "Action Successfull",
      description: "Copied the text: " + copyText.value,
      placement: "bottomRight",
    });
  }


  async sendInvite() {
    const { key , email} = this.state;
    const meetingUrl = `/join?m=${key}`;
    // console.log(key, " : ", meetingUrl);

    if(email === "no-email"){
      notification.error({
        message: "Email is not proper Format",
        description: "Please atleast type correct email before send invite",
        placement: "bottomRight",
      });

      return;
    }

    const resp = await axios.post("/send-email", {
      key: key,
      link: this.state.code,
      email: email
    });
    // console.log(resp);
    if (resp.data.response === true) {
      notification.success({
        message: "Action Successfull",
        description: "Invite has been sent",
        placement: "bottomRight",
      });
    } else {
      notification.error({
        message: "Something went wrong",
        description: "Please try again",
        placement: "bottomRight",
      });
    }
  }

  render() {
    return (
      <>
        <div className="table-addmanager">
          <div className="modal-addmanager">
            <div className="modal-content border-none">
              <div className="modal-header">
                <h5 className="modal-title" id="areamanager">
                  Start New Session
                </h5>
              </div>

              <div className="modal-body">
                <form
                  onSubmit={this.submitAddSessionForm.bind(this)}
                  className="login-form"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="First">
                          Customer ID<sup>*</sup>
                        </label>
                        <input
                          placeholder="Customer ID"
                          type="text"
                          className="form-control"
                          name="id"
                          aria-describedby="emailHelp"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="Last">Mobile Number</label>
                        <input
                          placeholder="MObile Number (Optional)"
                          type="text"
                          className="form-control"
                          id="Last"
                          name="number"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input
                          type="email"
                          placeholder="Email address (Optional)"
                          className="form-control"
                          name="email"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="Email">Description</label>
                        <textarea
                          type="text"
                          placeholder="Description (Optional)"
                          className="form-control"
                          name="desc"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary">
                          GENERATE CODE
                        </button>
                        <button
                          onClick={this.sendInvite.bind(this)}
                          style={{ marginLeft: "25px" }}
                          type="button"
                          className="btn btn-primary"
                          disabled={this.state.disable}
                        >
                          Send Invite
                        </button>

                        <span style={{marginLeft: "10px", color: "red"}}>Note: Please start session bofore send invite...</span>
                      </div>
                    </div>
                  </div>
                </form>
                {/*when click generate button this section will show */}
                <div className="generate-key">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group">
                        <label>Generated Key</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-key" aria-hidden="true"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Generate code"
                            id="keyValue"
                            readOnly
                            defaultValue={this.state.key}
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text bg-none copy-btn"
                              onClick={() => this.copyBtnHandler("keyValue")}
                            >
                              <i
                                className="fa fa-files-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                        </div>
                        {/* <small>{this.state.showTimer ? <Timer /> : null}</small> */}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <p className="or-text">OR</p>
                    </div>
                    <div className="col-md-5">
                      <div className="form-group">
                        <label>QR Code Genrator</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-key" aria-hidden="true"></i>
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="urlValue"
                            placeholder="Generate QR"
                            readOnly
                            defaultValue={this.state.code}
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text bg-none copy-btn"
                              onClick={() => this.copyBtnHandler("urlValue")}
                            >
                              <i className="fa fa-files-o" aria-hidden="true" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-center">
                        <StartSessionButton
                          disable={this.state.disable}
                          meetingID={this.state.meetingID}
                          manager={this.props.user.email}
                          // click = {this.clickStartButton}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.login,
  };
}

export default connect(mapStateToProps)(Addsession);
