import React, { Component } from "react";
import { connect } from "react-redux";
import { adduser, fetchUsers, changeStatus } from "../../../actions/user";
import { Table, Space } from "antd";

class Managerlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "",

      editfirstname: "",
      editlastname: "",
      editemail: "",
      editID: "",
      editrole: "",
    };

    this.props.dispatch(fetchUsers());
  }

  submitAddUserForm(event) {
    event.preventDefault();
    const {
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      role,
    } = this.state;

    if (password === confirmPassword) {
      this.props.dispatch(
        adduser(firstname, lastname, email, password, role, "add", "id")
      );
    } else {
      alert("Password did not matched");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.changeStatus) {
      if (nextProps.changeStatus.message) {
        window.location.reload();
      }
    }
    // console.log(nextProps);
    if (nextProps.user.addUser) {
      if (nextProps.user.addUser.message) {
        console.log("Add user static Props", nextProps.user.addUser.message);
        window.location.reload();
      } else {
        console.log("Error: ", nextProps.user.addUser);
      }
    }
    return true;
  }
  editHandler(record) {
    this.setState({
      editfirstname: record.firstname,
      editlastname: record.lastname,
      editemail: record.username,
      editrole: record.userRole,
      editID: record.id,
    });
    // console.log(record.userRole)
  }

  changeStatus(id, status) {
    this.props.dispatch(changeStatus(id, status));
  }

  editFormSubmitted(e) {
    e.preventDefault();
    this.props.dispatch(
      adduser(
        e.target.firstname.value,
        e.target.lastname.value,
        e.target.email.value,
        "password",
        e.target.role.value,
        "update",
        e.target.id.value
      )
    );
  }

  render() {
    if (!this.props.userList) {
      return <div className="loader">Loading</div>;
    }
    if(!this.props.userList.list){
      window.location.reload();
    }
    const listData = this.props.userList.list.Items;
    const columns = [
      {
        title: "UID",
        dataIndex: "id",
        key: "id",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Created date",
        dataIndex: "addedOn",
        key: "addedOn",
        render: (text) => {
          const time = new Date(text).toDateString();
          return <>{time}</>;
        },
      },
      {
        title: "Role",
        dataIndex: "userRole",
        key: "userRole",
        render: (text) => {
          return <span>{text === "1" ? "Super Admin" : "Manager"}</span>;
        },
      },

      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {this.props.user.login.role === "1" ? (
              <>
                <i
                  className="fa fa-pencil-square-o"
                  aria-hidden="true"
                  data-toggle="modal"
                  data-target="#editmanager"
                  onClick={() => this.editHandler(record)}
                ></i>

                {record.userStatus === "1" ? (
                  <i
                    onClick={() => this.changeStatus(record.id, "0")}
                    className="fa fa-ban"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    onClick={() => this.changeStatus(record.id, "1")}
                    className="fa fa-check"
                    aria-hidden="true"
                  ></i>
                )}
              </>
            ) : (
              "No action"
            )}
          </Space>
        ),
      },
    ];
    return (
      <>
        {this.props.user.login.role === "1" ? (
          <div className="add-manager">
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addmanager"
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              ADD MANAGER
            </button>
          </div>
        ) : null}
        <div className="table-addmanager">
          <Table
            columns={columns}
            rowKey={listData.addedOn}
            dataSource={listData}
          />
        </div>

        {/*addmanager modal start here */}
        <div
          className="modal fade modal-addmanager"
          id="addmanager"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="areamanager"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="areamanager">
                  Add User
                </h5>
              </div>

              <div className="modal-body">
                <form
                  className="login-form"
                  onSubmit={this.submitAddUserForm.bind(this)}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="First">First Name</label>
                        <input
                          placeholder="First Name"
                          type="text"
                          className="form-control"
                          name="firstname"
                          aria-describedby="emailHelp"
                          onChange={(e) =>
                            this.setState({ firstname: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Last">Last Name</label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          className="form-control"
                          name="lastname"
                          id="lastname"
                          aria-describedby="emailHelp"
                          required
                          onChange={(e) =>
                            this.setState({ lastname: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Email">Email address</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          aria-describedby="emailHelp"
                          required
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Password">Password</label>
                        <input
                          placeholder="Password"
                          type="password"
                          name="password"
                          className="form-control"
                          id="Password"
                          required
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="ConfirmPassword">
                          Confirm Password
                        </label>
                        <input
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          type="password"
                          className="form-control"
                          id="ConfirmPassword"
                          required
                          onChange={(e) =>
                            this.setState({ confirmPassword: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Role</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          name="role"
                          onChange={(e) =>
                            this.setState({ role: e.target.value })
                          }
                          defaultValue="2"
                          required
                        >
                          <option value="2" disabled>
                            Select Role
                          </option>
                          <option value="0">Manager</option>
                          <option value="1">Super Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-flexend">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*addmanager modal ends here */}

        {/*editmanager modal start here */}
        <div
          className="modal fade modal-addmanager"
          id="editmanager"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="areamanager"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="areamanager">
                  Edit User
                </h5>
              </div>

              <div className="modal-body">
                <form
                  onSubmit={this.editFormSubmitted.bind(this)}
                  className="login-form"
                >
                  <input
                    type="hidden"
                    name="id"
                    defaultValue={this.state.editID}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="First">First Name</label>
                        <input
                          placeholder="First Name"
                          type="text"
                          className="form-control"
                          name="firstname"
                          defaultValue={this.state.editfirstname}
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Last">Last Name</label>
                        <input
                          placeholder="Last Name"
                          type="text"
                          className="form-control"
                          name="lastname"
                          defaultValue={this.state.editlastname}
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="Email">Email address</label>
                        <input
                          type="email"
                          placeholder="Email address"
                          className="form-control"
                          name="email"
                          defaultValue={this.state.editemail}
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Role</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          name="role"
                          required
                        >
                          <option value="2" disabled>
                            Select Role
                          </option>
                          <option selected={this.state.editrole == 0 ? true : false} value="0">Manager</option>
                          <option selected={this.state.editrole == 1 ? true : false} value="1">Super Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-flexend">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*editmanager modal ends here */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAdd: state.userAdd,
    userList: state.user.users_list,
    changeStatus: state.user.changeStatus,
    user: state.user,
  };
}

export default connect(mapStateToProps)(Managerlist);
