import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from './hoc/layout'
// import Home from "./components/pages/Home/Home";
import Login from "./components/pages/Home/login";
import Managerlist from "./components/pages/Home/managerlist";
import Customerlog from "./components/pages/Home/Customerlog";
import Addsession from "./components/pages/Home/Addsession";
import Startsession from "./components/pages/Home/Startsession";
import Auth from "./hoc/auth";

const NotFound = () => <div>Page not found</div>;

const Routes = () =>{
    return(
        <Layout>
            <Switch>
                <Route path ="/" exact component={Auth(Managerlist, true)}/>  
                <Route path ="/login" exact component={Auth(Login, false)} />
                {/* <Route path ="/admin/managerlist" exact component={Auth(Managerlist, true)}/>  */}
                <Route path ="/Customerlog" exact component={Auth(Customerlog,true) }/> 
                <Route path ="/Addsession" exact component={Auth(Addsession, true)}/>  
                <Route path ="/Startsession" exact component={Auth(Startsession,true)}/>
                <Route path ="/Startsession" exact component={Auth(Startsession,true)}/>

                <Route path="*" component={NotFound} />
            </Switch>
        </Layout>        
    );
};

export default Routes;