import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../../actions/user";
import {  notification } from "antd";
class Login extends Component {
  componentWillReceiveProps(nextProps) {
    const { login } = nextProps.login.user;
    if (login) {
      // console.log(login)
      if (login.error === true) {
          notification.error({
            message: "Somthing is not right",
            description: "Please check email or password",
            placement: "bottomRight"
          })
        // nextProps.dispatch(clearLoginUser());
      }
    }
    return 1;
  }

  LoginFormSubmit(e) {
    e.preventDefault();
    this.props.dispatch(
      loginUser(e.target.email.value, e.target.password.value)
    );
  }

  render() {
    return (
      <>
        <header className="header">
          <div className="container-fluid">
            <div className="header-in">
              <div className="login-left">
                <img src="/images/caps-lock.png" alt="caps-lock" />
                <div className="loginleft-content">
                  <h5>LOGIN TO INSIQUAL</h5>
                  <p>Enter your credential below</p>
                </div>
              </div>
              <ul className="header-menu">
                <li>
                  <a href="/">
                    <img src="/images/logoblue.png" alt="caps-lock" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <div className="login-block">
          <div className="container">
            <form className="login-form" onSubmit={this.LoginFormSubmit.bind(this)}>
              <h2>Login</h2>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Username</label>
                <input
                  placeholder="Username"
                  type="email"
                  name="email"
                  className="form-control"
                  required
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="form-group">
                <div className="form-flex-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <label>
                    <a href="/">Forgot Password?</a>
                  </label>
                </div>
                <input
                  placeholder="Password"
                  name="password"
                  type="password"
                  required
                  className="form-control"
                  id="exampleInputPassword1"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Login
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state,
  };
}
export default connect(mapStateToProps)(Login);
