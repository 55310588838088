import React,{Component} from 'react';

class Footer extends Component{

    render(){
        return(
            <footer className="footer"> 
                <div className="container">
                    <p> &copy; copyright 2020. All Right reserved by Insiqual</p>
                </div>
            </footer>


        )
    }
}

export default Footer;