import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";

class Sidebar extends Component {
  render() {
    const urlSegment = window.location.href;
    let host = false;
    if(!urlSegment.includes("customerlog") && !urlSegment.includes("addsession")){
      host = true;
    }
    // console.log(urlSegment);
    // if(this.props.login){
    //     if (!this.props.login.isAuth) {
    //         return <> </>
    //     }
    // }
    return (
      <ul className="sidebarmenu">
        <li>
          {/*add isactive class on onclick */}
          <a  className={host ? 'isactive': 'notactive'} href="/">
            <i className="fa fa-users" aria-hidden="true"></i>
            Manager List
          </a>
        </li>
        <li>
          <a className={urlSegment.includes("addsession") ? `isactive` : 'notactive'} href="/addsession">
            <i className="fa fa-laptop" aria-hidden="true"></i>
            Add Session
          </a>
        </li>
        <li>
          <a className={urlSegment.includes("customerlog") ? `isactive` : 'notactive'}  href="/customerlog">
            <i className="fa fa-sign-in" aria-hidden="true"></i>
            Customer Logs
          </a>
        </li>
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.user.login,
  };
}

export default connect(mapStateToProps)(Sidebar);
