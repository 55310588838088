import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "antd";
import {  getLogs } from "../../../actions/user";

class Customerlog extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(getLogs());
  }


  render() {

    if(!this.props.logs){
        return <div className="loader"></div>
    }
    
    const dataSource = this.props.logs.list.Items;

    const columns = [
      {
        title: "CUSTOMER ID",
        dataIndex: "customerId",
        key: "customerId",
      },
      {
        title: "MANAGER EMAIL",
        dataIndex: "managerEmail",
        key: "managerEmail",    
      },
      {
        title: "START DATE/TIME",
        dataIndex: "meetingStartTime",
        key: "meetingStartTime",
      },
      {
        title: "END DATE/TIME",
        dataIndex: "meetingEndTime",
        key: "meetingEndTime",
      },
    ];

    return (
      <>
        <div className="table-addmanager">
          <Table dataSource={dataSource} columns={columns} />
          {/* <nav aria-label=" Page navigation example">
            <ul className="pagination mypagination">
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    logs: state.user.logs,
  };
}

export default connect(mapStateToProps)(Customerlog);
