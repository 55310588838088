export default function (state = { users_list: false }, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, login: action.payload };
    case "AUTH_CHECK":
      return { ...state, login: action.payload };
    case "Add_USER":
      return { ...state, addUser: action.payload };
    case "LIST_USERS":
      return { ...state, users_list: action.payload };
    case "USER_LOGIN_CLEAN":
      return { ...state, login: action.payload };
    case "USER_lOGOUT":
      return { ...state, logout: action.payload };
    case "CHANGE_PASSWORD":
      return { ...state, changePassword: action.payload };
    case "CHANGE_STATUS":
      return { ...state, changeStatus: action.payload };
    case "GET_CUS_LOGS":
      return { ...state, logs: action.payload };
    default:
      return state;
  }
}
