import React,{Component} from 'react';

class Startsession extends Component{

    render(){
        return(
            <>
            <div className="table-addmanager">
                <div className="Startsession-block">
                    <img src="/images/image-mob.png" alt="mob" />
                    <a href="/Addsession" className="share-btn btn btn-danger">
                        <img src="/images/share.png" alt="share-icon"/>
                        CLOSE SESSION
                    </a>
                </div>
            </div>

        </>
        )
    }
}

export default Startsession;